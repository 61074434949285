/* eslint-disable camelcase */
import React, { FC, useState, useMemo, useRef } from 'react';
import { graphql } from 'gatsby';
import {
  PageLayout,
  RecipeList,
  Filter,
  ParagraphWithGradientTop,
  Pagination,
  PageHeading,
} from 'components';
import { RecipePageType, RecipeListType, FilterType } from 'types';

interface ReceipesPageProps {
  data: {
    Recipes: RecipeListType;
    RecipesPage: RecipePageType;
    RecipeFilters: FilterType;
  };
}

const PageSize = 8;

const Receipes: FC<ReceipesPageProps> = ({ data }: any) => {
  const {
    RecipesPage: { pageTheme, title, description, seo, banner },
    Recipes,
    RecipeFilters,
    strapiLearningHubPage,
  } = data;
  const { learningBanner } = strapiLearningHubPage;
  const [FilteredValue, setFilteredValue] = useState(Recipes.edges);
  const [currentPage, setCurrentPage] = useState(1);
  const RecipeFilter = RecipeFilters.edges.map(recipe => recipe.node.title);
  const RecipeListData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return FilteredValue.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, FilteredValue]);
  const titleref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const handleonClick = (page: any) => {
    setCurrentPage(page);
    window.scrollTo(0, titleref.current.offsetTop);
  };
  return (
    <PageLayout
      title="LearningHub"
      className={`${pageTheme}`}
      hasContainerClass
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.localFile?.publicURL}
      bannerTitle={banner?.title || learningBanner.title}
      bannerTagline={banner?.tagline || learningBanner.tagline}
      bannerImage={banner?.image || learningBanner.image}
      bannerButton={banner?.ctaButton || learningBanner.ctaButton}
      bannerPlaceholder={
        banner?.postcodePlaceholder || learningBanner.postcodePlaceholder
      }
      dealTitle="Recipe Page"
    >
      <PageHeading title={title} level={1} />
      <Filter
        Filters={RecipeFilter}
        setFilteredList={setFilteredValue}
        ListToFilter={Recipes.edges}
        FilterCategory="recipe_tags"
        setCurrentPage={setCurrentPage}
        titleref={titleref}
      />
      <ParagraphWithGradientTop>{description}</ParagraphWithGradientTop>
      <RecipeList Recipes={RecipeListData} />
      <Pagination
        currentPage={currentPage}
        totalCount={FilteredValue.length}
        pageSize={PageSize}
        onPageChange={handleonClick}
      />
    </PageLayout>
  );
};

export default Receipes;

export const query = graphql`
  query RecipePageQuery {
    RecipesPage: strapiRecipesPage {
      title
      description: pageDescription
      pageTheme
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
    }
    Recipes: allStrapiRecipe(sort: { fields: strapiId, order: DESC }) {
      edges {
        node {
          id
          strapiId
          title
          duration
          slug
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 518, height: 518)
              }
            }
          }
          recipe_tags {
            title
          }
        }
      }
    }
    RecipeFilters: allStrapiRecipeTag {
      edges {
        node {
          title
        }
      }
    }
    strapiLearningHubPage {
      learningBanner: banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
